import request from "@/utils/request";

// 老师列表
export function TeacherList(params) {
    return request({
        url: '/admin/base/teacherList',
        method: 'get',
        params
    })
}

// 获取老师分类
export function TeacherType(params) {
    return request({
        url: '/admin/base/teacherType',
        method: 'get',
        params
    })
}

// 添加老师
export function TeacherAdd(data) {
    return request({
        url: '/admin/base/teacherAdd',
        method: 'post',
        data
    })
}


// 编辑老师
export function TeacherEdit(data) {
    return request({
        url: '/admin/base/teacherEdit',
        method: 'put',
        data
    })
}

// 删除老师
export function TeacherDel(data) {
    return request({
        url: '/admin/base/teacherDel',
        method: 'delete',
        data
    })
}

// 老师禁用或启用
export function TeacherDisplay(data) {
    return request({
        url: '/admin/base/teacherDisplay',
        method: 'put',
        data
    })
}

// 老师上传COS签名
export function getTeacherCosToken(params) {
    return request({
        url: '/admin/base/getTeacherCosToken',
        method: 'get',
        params
    })
}
