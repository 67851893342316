<template>
  <div>
    <el-card>
      <el-form ref="form" :inline="true">
        <el-form-item label="老师分类">
          <el-select
            v-model="queryForm.mold"
            placeholder="请选择老师分类"
            clearable
          >
            <el-option
              :label="item.value"
              :value="item.key"
              v-for="item in teacherTypeData"
              :key="item.key"
            ></el-option>
          </el-select>
        </el-form-item>
         <el-form-item label="启用状态">
          <el-select
            v-model="queryForm.display"
            placeholder="请选择启用状态"
            clearable
          >
            <el-option
              :label="item.value"
              :value="item.key"
              v-for="item in displayArr"
              :key="item.key"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="时间">
          <el-date-picker
            type="datetimerange"
            v-model="times"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="toQuery" icon="el-icon-search"
            >查询</el-button
          >
        </el-form-item>
        <el-form-item class="generate">
          <el-button type="primary" @click="toAdd"
            >添加老师</el-button
          >
        </el-form-item>
      </el-form>
      <el-table
        :data="teacherList"
        stripe
        class="table"
        ref="userTable.multipleSelection"
        header-cell-class-name="table-header"
        border
      >
        <el-table-column
          type="selection"
          width="55"
          align="center"
        ></el-table-column>

        <el-table-column
          prop="id"
          label="ID"
          width="55"
          align="center"
        ></el-table-column>
       
        <el-table-column label="老师科目" prop="mold" align="center"></el-table-column>
        <el-table-column label="老师名称" prop="name" align="center"></el-table-column>
        <el-table-column label="描述" prop="desc" align="center"></el-table-column>
        <el-table-column label="头像" align="center">
          <template slot-scope="scope">
            <div class="thumb_box">
                <img :src="scope.row.avatar_url" alt="">
            </div>
           
          </template>
        </el-table-column>
        <el-table-column label="启用状态" prop="display" align="center">
             <template slot-scope="scope">
            <el-tag
              type="success"
              v-if="scope.row.display == 1"
              @click="editState(scope.row.id, 0)"
            >
              启用
            </el-tag>
            <el-tag type="danger" v-else @click="editState(scope.row.id, 1)"
              >禁用
            </el-tag>
          </template>
        </el-table-column>
        <el-table-column label="时间" align="center">
          <template slot-scope="scope">
            {{
                $moment(scope.row.create_time * 1000).format(
                  "YYYY-MM-DD HH:mm:ss"
                )
              }}
          </template>
        </el-table-column>
       
        <el-table-column label="操作" align="center" >
          <template slot-scope="scope">
            <el-button
              type="primary"
              icon="el-icon-edit"
              circle
              @click="editGoods(scope.row)"
              size="small"
            ></el-button>
            <el-button
              type="danger"
              icon="el-icon-delete"
              circle
              @click="removeTask(scope.row.id)"
              size="small"
            ></el-button>
          </template>
        </el-table-column>
      </el-table>

       <!-- 分页区域 -->
     <div class="pagination">
       <span></span>
        <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="queryForm.page"
        :page-sizes="[3, 5, 8, 10]"
        :page-size="queryForm.limit"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
        algin:="center"
      ></el-pagination>
     </div>

      <!-- 添加老师 -->
      <el-dialog :title="addtitle" :visible.sync="addDialog" :center=true width="40%" @close="closed" :close-on-click-modal=false>
      <el-form :model="addForm"  ref="addForm">
        <el-form-item el-form-item label="老师分类" label-width="100px">
            <el-select
              v-model="addForm.mold"
              placeholder="请选择老师分类"
              clearable
            >
              <el-option
                :label="item.value"
                :value="item.key"
                v-for="item in teacherTypeData"
                :key="item.key"
              ></el-option>
            </el-select>
        </el-form-item>

        <el-form-item el-form-item label="启用状态" label-width="100px">
            <el-select
              v-model="addForm.display"
              placeholder="请选择启用状态"
              clearable
            >
              <el-option
                :label="item.value"
                :value="item.key"
                v-for="item in displayArr"
                :key="item.key"
              ></el-option>
            </el-select>
        </el-form-item>

        <el-form-item el-form-item label="老师名字" label-width="100px">
            <el-input
              type="text"
              v-model="addForm.name"
              placeholder="请输入老师名字"
            />
        </el-form-item>

        <el-form-item el-form-item label="老师照片" label-width="100px">
            <el-upload
              class="avatar-uploader"
              action="123"
              :show-file-list="false"
              accept="image/*"
              :before-upload="beforeThumbUpload"
            >
                <img
                  v-if="thumbSrc"
                  style="height:100%"
                  :src="thumbSrc"
                  class="avatar"
                />
                <i
                v-else
                class="el-icon-plus avatar-uploader-icon"
                style="margin-top: 85px;"
                ></i>
            </el-upload>
        </el-form-item>

        <el-form-item el-form-item label="app展示" label-width="100px">
            <el-upload
              class="avatar-uploader"
              action="123"
              :show-file-list="false"
              accept="image/*"
              :before-upload="beforeAppThumbUpload"
            >
                <img
                  v-if="appThumbSrc"
                  style="height:100%"
                  :src="appThumbSrc"
                  class="avatar"
                />
                <i
                v-else
                class="el-icon-plus avatar-uploader-icon"
                style="margin-top: 85px;"
                ></i>
            </el-upload>
        </el-form-item>
        <el-form-item el-form-item label="描述" label-width="100px">
            <el-input
            type="textarea"
            :rows="6"
            placeholder="请输入描述"
            v-model="addForm.desc"
          />
        </el-form-item>

        <el-form-item el-form-item label="内容：" label-width="100px">
            <quill-editor
              v-model="addForm.content"
              ref="myQuillEditor"
            >
            </quill-editor>
        </el-form-item>
        <el-form-item label-width=40% style="margin-top: 20px;">
           <el-button type="primary" v-if="isAdd=='add'" @click="addNow" >立即添加</el-button>
           <el-button type="primary" v-else @click="editReadNow">立即修改</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>

    </el-card>
  </div>
</template>

<script>
//引入富文本组件
import { quillEditor } from "vue-quill-editor";
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import "quill/dist/quill.bubble.css";
var COS = require("cos-js-sdk-v5");

import {
  TeacherList,
  TeacherType,
  TeacherAdd,
  TeacherEdit,
  TeacherDel,
  TeacherDisplay,
  getTeacherCosToken,
} from "@/api/teacher_list.js";
export default {
  components: {
    quillEditor,
  },
  data() {
    return {
      queryForm: {
        mold: null,
        display: null,
        page: 1,
        limit: 10,
        start_time: null,
        end_time: null,
      },
      teacherTypeData: [],
      teacherList: [],
      displayArr: [
        { key: 0, value: "禁用" },
        { key: 1, value: "启用" },
      ],
      total: 0,
      addDialog: false,
      times: null,
      addForm: {
        mold: "",
        name: "",
        desc: "",
        avatar: "",
        show_app: "",
        display: null,
        content: "",
      },
      addtitle: "",
      isAdd: "add",
      thumbSrc: "",
      appThumbSrc: "",
    };
  },

  created() {
    this.getTeacherList();
    this.getTeacherType();
  },
  methods: {
    //   获取老师列表
    getTeacherList() {
      TeacherList(this.queryForm).then((res) => {
        if (res.code !== 1) {
          this.$message.error(res.msg);
          return;
        }
        this.total = res.data.total;
        this.teacherList = res.data.data;
      });
    },
    // 获取老师分类
    getTeacherType() {
      TeacherType().then((res) => {
        this.teacherTypeData = res.data;
      });
    },

    // 删除老师
    async removeTask(id) {
      const result = await this.$confirm("是否要删除该老师?", "删除提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).catch((err) => err);
      if (result !== "confirm") return this.$message.info("已经取消删除");
      TeacherDel({ id: id }).then((res) => {
        if (res.code !== 1) {
          this.$message.error(res.msg);
          return;
        }
        this.$message.success("删除老师成功");
        this.getTeacherList();
      });
    },
    //   老师状态
    editState(id, index) {
      let data = {
        id: id,
        display: index,
      };
      TeacherDisplay(data).then((res) => {
        if (res.code !== 1) {
          this.$message.error(res.msg);
          return;
        }
        this.$message.success("修改老师状态成功");
        this.getTeacherList();
      });
    },

    // 添加弹窗
    toAdd() {
      this.isAdd = "add";
      this.addtitle = "添加老师";
      this.addDialog = true;
    },

    // 上传缩略图
    beforeThumbUpload(file) {
      const isSize = file.size / 1024 / 1024 < 10;
      if (!isSize) {
        this.$message.error("上传老师照片不能超过 10MB!");
        return;
      }
      let that = this;
      getTeacherCosToken({ suffix: file.name }).then((res) => {
        var cos = new COS({
          getAuthorization: function (options, callback) {
            callback({
              TmpSecretId: res.data.credentials.tmpSecretId,
              TmpSecretKey: res.data.credentials.tmpSecretKey,
              XCosSecurityToken: res.data.credentials.sessionToken,
              StartTime: res.data.startTime,
              ExpiredTime: res.data.expiredTime,
              expiration: res.data.expiration,
              requestId: res.data.requestId,
            });
          },
        });
        cos.putObject(
          {
            Bucket: res.data.Bucket,
            Region: res.data.Region,
            Key: res.data.key,
            // ContentType:'multipart/form-data',
            Body: file, // 上传文件对象
            onProgress: function (progressData) {},
          },
          function (err, data) {
            if (data) {
              that.addForm.avatar = res.data.key;
              const reader = new FileReader();
              reader.readAsDataURL(file);
              reader.onload = (e) => {
                that.thumbSrc = e.target["result"]; // 获取文件装换后的Base64位文件
                that.$forceUpdate();
              };
            }
          }
        );
      });
    },

    // app展示照
    beforeAppThumbUpload(file) {
      const isSize = file.size / 1024 / 1024 < 10;
      if (!isSize) {
        this.$message.error("上传app端展示照不能超过 10MB!");
        return;
      }
      let that = this;
      getTeacherCosToken({ suffix: file.name }).then((res) => {
        var cos = new COS({
          getAuthorization: function (options, callback) {
            callback({
              TmpSecretId: res.data.credentials.tmpSecretId,
              TmpSecretKey: res.data.credentials.tmpSecretKey,
              XCosSecurityToken: res.data.credentials.sessionToken,
              StartTime: res.data.startTime,
              ExpiredTime: res.data.expiredTime,
              expiration: res.data.expiration,
              requestId: res.data.requestId,
            });
          },
        });
        cos.putObject(
          {
            Bucket: res.data.Bucket,
            Region: res.data.Region,
            Key: res.data.key,
            // ContentType:'multipart/form-data',
            Body: file, // 上传文件对象
            onProgress: function (progressData) {},
          },
          function (err, data) {
            if (data) {
              that.addForm.show_app = res.data.key;
              const reader = new FileReader();
              reader.readAsDataURL(file);
              reader.onload = (e) => {
                that.appThumbSrc = e.target["result"]; // 获取文件装换后的Base64位文件
                that.$forceUpdate();
              };
            }
          }
        );
      });
    },

    // 添加老师
    addNow() {
      if (this.addForm.mold == "") {
        this.$message.error("请选择老师分类");
        return;
      } else if (this.addForm.display == null || this.addForm.display === "") {
        this.$message.error("请选择启用状态");
        return;
      } else if (this.addForm.name == "") {
        this.$message.error("请输入老师名字");
        return;
      } else if (this.addForm.avatar == "") {
        this.$message.error("请上传老师照片");
        return;
      } else if (this.addForm.show_app == "") {
        this.$message.error("请上传app端老师展示照片");
        return;
      } else if (this.addForm.desc == "") {
        this.$message.error("请输入老师介绍");
        return;
      } else if (this.addForm.content == "") {
        this.$message.error("请输入内容");
        return;
      }

      TeacherAdd(this.addForm).then((res) => {
        if (res.code !== 1) {
          this.$message.error(res.msg);
          return;
        }
        this.$message.success("添加老师成功");
        this.addDialog = false;
        this.getTeacherList();
      });
    },

    // 点击编辑图标
    editGoods(item) {
      this.isAdd = "edit";
      this.addtitle = "编辑老师";
      this.addDialog = true;
      this.addForm = JSON.parse(JSON.stringify(item));
      this.thumbSrc = this.addForm.avatar_url;
      this.appThumbSrc = this.addForm.show_app_url;
    },

    // 点击立即修改
    editReadNow() {
      if (this.addForm.mold == "") {
        this.$message.error("请选择老师分类");
        return;
      } else if (this.addForm.display == null || this.addForm.display === "") {
        this.$message.error("请选择启用状态");
        return;
      } else if (this.addForm.name == "") {
        this.$message.error("请输入老师名字");
        return;
      } else if (this.addForm.avatar == "") {
        this.$message.error("请上传老师照片");
        return;
      } else if (this.addForm.show_app == "") {
        this.$message.error("请上传app端老师展示照片");
        return;
      } else if (this.addForm.desc == "") {
        this.$message.error("请输入老师介绍");
        return;
      } else if (this.addForm.content == "") {
        this.$message.error("请输入内容");
        return;
      }

      TeacherEdit(this.addForm).then((res) => {
        if (res.code !== 1) {
          this.$message.error(res.msg);
          return;
        }
        this.$message.success("修改老师成功");
        this.addDialog = false;
        this.getTeacherList();
      });
    },

    // 弹窗关闭事件
    closed() {
      this.addForm = {
        mold: "",
        name: "",
        desc: "",
        avatar: "",
        show_app: "",
        display: null,
        content: "",
      };
      this.thumbSrc = "";
      this.appThumbSrc = "";
    },

    //  查询
    toQuery() {
      if (this.queryForm.display === "") {
        this.queryForm.display = null;
      }
      this.queryForm.page = 1;
      if (this.times) {
        this.queryForm.start_time = new Date(this.times[0]).getTime() / 1000;
        this.queryForm.end_time = new Date(this.times[1]).getTime() / 1000;
      } else if (this.times == null) {
        this.queryForm.start_time = null;
        this.queryForm.end_time = null;
      }
      this.getTeacherList();
    },
    handleSizeChange(size) {
      this.queryForm.limit = size;
      this.getTeacherList();
    },
    handleCurrentChange(page) {
      this.queryForm.page = page;
      this.getTeacherList();
    },
  },
};
</script>

<style scoped lang="less">
.generate {
  float: right;
}
.el-tag {
  cursor: pointer;
}
::v-deep .ql-editor {
  min-height: 400px;
}
.thumb_box {
  width: 100%;
  height: 100%;

  img {
    width: 120px;
    height: 120px;
  }
}
</style>
